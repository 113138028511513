<template>
    <div v-if="visible" :class="['toast', type]" @click="closeToast">
      <div class="flex justify-center items-center">
        <div class="max-w-max"> {{ message }} </div>
        <div class="ml-3">
          <svg @click="closeToast" class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { EventBus } from '../../../src/event-bus';
  
  export default {
    data() {
      return {
        message: '',
        type: '',
        visible: false,
      };
    },
    created() {
      EventBus.$on('show-toast', this.showToast);
      
    },
    methods: {
      showToast({ message, type }) {
        this.message = message;
        this.type = type;
        this.visible = true;
        setTimeout(() => {
          this.visible = false;
        }, 3000);
      },
      closeToast() {
        this.visible = false;
      },
    },
      beforeDestroy() {
    EventBus.$off('show-toast', this.showToast);
  }

  };
  </script>
  
  <style>
  .toast {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 50px);
    padding: 13px 20px;
    border-radius: 5px;
    color: white;
    background-color: #333;
    opacity: 1;
    z-index: 1000;
    max-width: unset;
  }
  
  .toast.success {
    background-color: #333;
  }
  
  .toast.error {
    background-color: #333;
  }
  
  .toast.warning {
    background-color: #333;
  }
  </style>
  