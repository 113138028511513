<template>
  <div class="app flex-row align-middle  bg-gray-50 ">
    <Toast />
    <div class="container flex relative justify-center h-screen items-center bg-gray-50 text-left">
      <div class="flex-col w-2/5 justify-center">
        <div>
         
          <div class="flex justify-center">
            <img
              src="../../assets/img/gramedia_digital.png"
              alt="admin@bootstrapmaster.com"
              class="mb-4"
            />
          </div>
          <div class="rounded-lg h-auto border shadow-md flex justify-center  bg-white">
          
            <div class="flex-col w-full justify-center">
              <h4 class="font-bold px-3 mt-4 mb-3">Log in</h4>
              <div class="mt-0 w-full px-3">
                <label for="email" class="block w-full mb-2 text-sm font-medium text-gray-900">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  placeholder="name@example.com"
                  autocomplete="email"
                  v-model="Form.email"
                  @input="validateEmail"
                  class="shadow-sm text-gray-500 bg-gray-50  p-2.5 w-full rounded-lg" required
                  :class="errors.email || errors.correct_email_password ? 'bg-red-50 border-custom' : 'border'"
                  />
                  <div v-if="errors.email" class="text-red-500 text-sm mt-2">{{ errors.email }}</div>
       
              </div>
            <div class="mt-3 w-full px-3 relative">
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
              <input 
                v-if="showPassword"
                type="text"
                v-model="Form.password"
                class="shadow-sm text-gray-500 bg-gray-50  p-2.5 w-full rounded-lg" 
                :class="errors.password || errors.correct_email_password ? 'bg-red-50 border-custom' : 'border'"
                placeholder="Password" 
                  @input="validatePassword"
                required />
                <input 
                v-else
                type="password"
                v-model="Form.password"
                class="shadow-sm text-gray-500 bg-gray-50  p-2.5 w-full rounded-lg" 
                :class="errors.password ||  errors.correct_email_password ? 'bg-red-50 border-custom' : 'border'"
                placeholder="Password" 
                @input="validatePassword"
                required />
              <span v-if="!showPassword"  class="icon-eye" @click="toggleShow">
                <svg width="14" height="14" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.5 5C0.5 4.9612 0.516005 4.86113 0.586554 4.69231C0.653822 4.53134 0.758302 4.3371 0.90084 4.12003C1.18576 3.68611 1.60426 3.18899 2.12311 2.71967C3.17053 1.77224 4.55575 1 6 1C7.44425 1 8.82947 1.77224 9.87689 2.71967C10.3957 3.18899 10.8142 3.68611 11.0992 4.12003C11.2417 4.3371 11.3462 4.53134 11.4134 4.69231C11.484 4.86113 11.5 4.9612 11.5 5C11.5 5.15567 11.4134 5.48848 11.1686 5.93527C10.9328 6.36553 10.5764 6.85308 10.1041 7.31097C9.16123 8.22514 7.78061 9 6 9C4.21939 9 2.83877 8.22514 1.8959 7.31097C1.42363 6.85308 1.06721 6.36553 0.831416 5.93527C0.586564 5.48848 0.5 5.15567 0.5 5ZM4.70892 7.0101C5.08776 7.28132 5.5371 7.42857 6 7.42857C6.62157 7.42857 7.21007 7.1637 7.63832 6.70487C8.06548 6.24719 8.3 5.63339 8.3 5C8.3 4.52705 8.16925 4.06219 7.92091 3.66396C7.67238 3.26545 7.31563 2.94938 6.89165 2.76122C6.46716 2.57283 5.99782 2.52288 5.54462 2.61947C5.09175 2.71598 4.68083 2.95319 4.36168 3.29513C4.04287 3.63671 3.82924 4.06787 3.74299 4.53249C3.65675 4.99702 3.70075 5.47885 3.87065 5.91833C4.04063 6.35799 4.33057 6.73924 4.70892 7.0101Z" :fill="this.errors.password || this.errors.correct_email_password ? '#ef4444' : '#6B7280'" :stroke="this.errors.password || this.errors.correct_email_password ? '#ef4444' : '#6B7280'"/>
                </svg>
               </span>
               <span v-else class="icon-eye" @click="toggleShow">
                <svg class="w-4 h-4 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg>

               </span>
               <div v-if="errors.password" class="text-red-500 text-sm mt-2">{{ errors.password }}</div>
       
            </div>
            <div class="mt-4 mb-5 w-full px-3 text-white text-right">
              <span class="text-blue-700 text-right">
                <router-link :to="{ path: '/forgotPassword' }">
                    Forgot password?
                </router-link>
              </span>
              <button class="w-full bg-blue-700 rounded-lg p-2.5 mt-3" @click="actionLogin" :disabled="isButtonDisabled">
                Log in
                <svg v-if="isLoading" aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                </svg>
              </button>
            </div>
            </div>
          </div>
      </div>
      </div>     
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Toast from '../../components/form/Toast.vue'
import { EventBus } from '../../../src/event-bus';  
export default {
  name: 'Login',
  components: {
        Toast
    },
  data() {
    return {
      Form: {},
      errors: {
        email: null,
        password: null,
        correct_email_password:false
      },
      isActive: false,
      showPassword: false,
      toastVisible: false,
      toastMessage: '',
      toastType: '',   
    };
  },
  computed: {
    ...mapState({
      success: (state) => state.login.isSuccess,
      message: (state) => state.login.successMessage,
      isLoading: (state) => state.login.isLoading,
      token: (state) => state.login.token,
      isError: (state) => state.login.isError,
    }),
    isDisable() {
      return !(
        this.Form.email &&
        this.Form.email.length > 4 &&
        this.Form.password &&
        this.Form.password.length > 4
      );
    },
    icon(){
      return this.showPassword ? 'eye' : 'eye-slash';
    },
    isButtonDisabled() {
      return this.errors.email === 'Please enter a valid email address.';
    }
  },
  watch: {    
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Login | Content Management System Ebooks Gramedia.com';
      },
    },
    isError: function() {
      if (this.isError) {
        if (this.message) {
          this.showAlert({
            success: false,
            message: 'Error Login, ' + this.message,
            title: 'Error Login',
          });
        } else {
          this.showAlert({
            success: false,
            message: 'Error Login',
            title: 'Error Login',
          });
        }
      }
    },
  },
  methods: {
    ...mapActions('login', ['loginAction']),
    ...mapActions('auth', ['setLogin', 'setAuth']),

    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.Form.email)) {
      this.Form.email.length > 1 ?  this.errors.email = 'Please enter a valid email address.' :this.errors.email = null
      } else {
        this.errors.email = null;
      }
      this.errors.correct_email_password = false
    },
    validatePassword() {
      this.Form.password.length > 1 ?  this.errors.password = null :''
      this.errors.correct_email_password = false
    },
    actionLogin() {
      this.errors.email = this.Form.email ? null : 'Must be filled.';
      this.errors.password = this.Form.password ? null : 'Must be filled' 
      let email =  this.Form.email.trim();
      let payload = {
        email,
        password: this.Form.password,
      };
      let message = 'Error Authentication Login';
      if(!this.errors.email && !this.errors.password && !this.errors.correct_email_password) {
        this.loginAction(payload)
        .then((response) => {
          if(response.message) {
            message = response.message;
          }
          const result = response.result;
          const resetpassword = response.resetpassword;
          const modeTwoFactor = response.twoFactor;
          const token = response.token; //this jwt if result same password and two factor, if not then token login
          if (resetpassword) {
            this.$router.push({
              path: `/change-password/${token}`,
            });
          } else if (modeTwoFactor) {
            if (result) {
              this.$router.push({
                path: `/twoFactor/${token}`,
              });
            } else {
              this.showAlert({
                success: false,
                message: message,
                title: 'Error',
              });
            }
          } else {
            if (result) {
              localStorage.setItem('session_name', response.username);
              localStorage.setItem('session_token', token);
              localStorage.setItem('refresh_token', response.refresh_token);
              localStorage.setItem('session_auth', JSON.stringify(response.data));
              this.setAuth(response.data);
              this.setLogin({ isLogin: true });
              EventBus.$emit('show-toast', {
                    message: message,
                    type: 'success',
                });
                localStorage.setItem('showWelcomeToast', 'true');
              setTimeout(() => {  
                window.location.reload();
              }, 2000);
            } else {
              EventBus.$emit('show-toast', {
                    message: "Email or password does not match. Please retry. ",
                    type: 'error',
                });
                this.errors.correct_email_password = true
            }
          }
        })
        .catch(() => {
          this.showAlert({
            success: false,
            message: 'Login Failed verification: ' + message,
            title: 'Error',
          });
        });
      }
    
      },
    toggleShow() {
      this.showPassword = !this.showPassword;

    },
  },
  mounted() {
    let token = localStorage.getItem('session_token');
    if (token) {
      this.$router.push({ path: '/' });
    }
  },
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}
.border-custom {
  border: 1px solid #ef4444;
}
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
@media (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }
  .display-mobile {
    display: none;
  }
}
.icon-eye { 
  display: flex;
  align-items: center;
  position: absolute;
  height: 77px;
  top: 14px;
  right: 30px;
  padding-right: 1px;
  cursor: pointer;
}
.custom-icon.icon-eye {
  top:0;
  color: #ef4444;
}
.custom-icon.icon-eye {
  color: #ef4444;
}

</style>
